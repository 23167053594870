<!-- xlsx.js (C) 2013-present  SheetJS -- http://sheetjs.com -->
<template>
  <div @dragenter="dragEnter" @dragover="dragEnter" @drop="_drop" style="position: relative;">
    <CJumbotron>
      <h1 class="display-3">{{ $t('domain.BatchImportOfDomainNames') }}</h1>

      <p>{{ $t('message.batch_domain_import') }} </p>

      <button id="download_import_file" :disabled="!data.length" class="btn btn-outline-primary" @click="_export">
        <CIcon name="cil-cloud-download" />&nbsp;
        {{ $t('DownloadImportFile') }}
      </button>

      <button id="upload_import_file" class="btn btn-primary" style="margin-left: 8px;" @click="handleSelectFile">
        <CIcon name="cil-cloud-upload" />&nbsp;
        {{ $t('UploadImportFile') }}
      </button>

      <input type="file" style="display: none;" @change="_change" ref="fileInput">

      <p style="margin-top: 8px;">{{ $t('message.EditFileDescription') }}： </p>
      <ol>
        <li>{{ $t('message.BatchImportDescriptionItem1') }}</li>
        <li>{{ $t('message.BatchImportDescriptionItem2') }}</li>
        <li>{{ $t('message.BatchImportDescriptionItem3') }}</li>
        <li>{{ $t('message.BatchImportDescriptionItem4') }}</li>
      </ol>

    </CJumbotron>

    <CCard>
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-grid" />
          {{ $t('FileExample') }}
        </slot>
      </CCardHeader>
      <CCardBody>
        <!-- <CDataTable
            :hover="hover"
            :striped="striped"
            :bordered="bordered"
            :small="small"
            :fixed="fixed"
            :items="data"
            :fields="fields"
            :items-per-page="100"
            :dark="dark"
            pagination
        > -->
        <CDataTable :items="data" :fields="fields" :items-per-page="100" pagination>
          <template #remarks="{item}">
            <td v-if="item._errors">
              <ul v-if="item._errors.length > 1" class="error-list">
                <li v-for="e in item._errors" :key="e">
                  <CIcon name="cil-warning" />&nbsp;
                  {{ e }}
                </li>
              </ul>
              <span v-else-if="item._errors.length === 1">
                <CIcon name="cil-warning" />&nbsp;
                {{ item._errors[0] }}
              </span>
            </td>
            <td v-else-if="item._result">
              <CIcon name="cil-check-circle" />&nbsp;
              {{ item._result }}
            </td>
            <td v-else-if="item.saving_shadow_domains">
              <CSpinner size="sm" />
              {{ $t('message.SavingShadowDomains') }}
            </td>
            <td v-else-if="item.loading">
              <CSpinner size="sm" />
              {{ $t('processing') }}
            </td>
            <td v-else-if="item.redeploy">
              <CSpinner size="sm" />
              {{ $t('message.RedeployingDomain') }}
            </td>
            <td v-else-if="item.onqueue">
              <CSpinner size="sm" />
            </td>
            <td v-else>
              &nbsp;
            </td>
          </template>
          <template #status={item}>
            <td>
              {{ $t(item.status) }}
            </td>
          </template>
          <template #auto_generate_cert="{item}">
            <td>
              {{ item.auto_generate_cert }}
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>

    <div class="row">
      <div class="col-xs-12"></div>
    </div>
    <div class="row">
      <div class="col-xs-12">

      </div>
    </div>

    <div class="drag-drop-indicator" :class="{ activeDrag: hasDraggedItem }"></div>
  </div>
</template>

<style scoped>
ul.error-list {
  list-style: none;
  padding-left: 0;
}

.drag-drop-indicator.activeDrag {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: steelblue;
  opacity: 0.2;
}
</style>

<script>
import XLSX from "xlsx";
import { Websocket } from "@/plugins/websocket";
import { $i18n } from '@/plugins/i18n';
import DomainValidationMixin from '@/utilities/DomainValidationMixin';
import { ExportDomainMixin, COLUMN_KEYS } from '@/mixins/ExportDomainMixin';
import { DomainAction } from '@/utilities/websocket';
import { MULTIPLE_VALUES_SPLITTER, TAG_NAMES  } from '@/utilities/constants';
import { sendNotification, getClientIP } from '../../utilities/api';

export default {
  mixins: [DomainValidationMixin, ExportDomainMixin],
  mounted() {
    this.openWs();
  },
  data() {
    return {
      message: "",
      hasDraggedItem: false,
      clearDragFlagRef: null,
      queueCreate: [],
      onProgressCreate: [],
      queueDelete: [],
      onProgressDelete: [],
      mutatedData: [],
      isDomainImportInProgress: false,
      clientIP: null,
    };
  },
  computed: {
    data: {
      get() {
        if (this.mutatedData.length === 0) {
          return [{
            [COLUMN_KEYS.NAME]: 'v03.example.com',
            [COLUMN_KEYS.ORIGIN_IP]: '8.8.8.8',
            [COLUMN_KEYS.PROTOCOL]: 'http',
            [COLUMN_KEYS.SHADOW_DOMAINS]: 'xyz.example.com shadow.example.com',
            [COLUMN_KEYS.STATUS]: this.$t('online'),
            [COLUMN_KEYS.AUTO_GENERATE_CERT]: false,
            [COLUMN_KEYS.REMARK]: 'notes'
          },
          {
            [COLUMN_KEYS.NAME]: 'v04.example.com',
            [COLUMN_KEYS.ORIGIN_IP]: '8.8.8.8',
            [COLUMN_KEYS.PROTOCOL]: 'https',
            [COLUMN_KEYS.SHADOW_DOMAINS]: '',
            [COLUMN_KEYS.STATUS]: this.$t('disabled'),
            [COLUMN_KEYS.AUTO_GENERATE_CERT]: true,
            [COLUMN_KEYS.REMARK]: 'notes'
          },
          {
            [COLUMN_KEYS.NAME]: 'v05.example.com',
            [COLUMN_KEYS.ORIGIN_IP]: '8.8.8.8,8.8.4.4',
            [COLUMN_KEYS.PROTOCOL]: 'http',
            [COLUMN_KEYS.SHADOW_DOMAINS]: '',
            [COLUMN_KEYS.STATUS]: this.$t('deleted'),
            [COLUMN_KEYS.AUTO_GENERATE_CERT]: false,
            [COLUMN_KEYS.REMARK]: 'notes'
          }]
        }
        return this.mutatedData;
      },
      set(data) {
        this.mudatedData = data;
      }
    },
    cname: {
      get() {
        return this.$store.state.cname;
      },
      set(value) {
        this.$store.state.cname = value;
      }
    },
    domain_ws_import: {
      get() {
        return this.$store.state.websocket.domain_ws_import;
      },
      set(value) {
        this.$store.state.websocket.domain_ws_import = value;
      }
    },    
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDomainImportInProgress) {
      const answer = window.confirm($i18n('ImportClosePageAlert'));
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else{
      next()
    }
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async openWs() {
      this.clientIP = await getClientIP();
      
      this.domain_ws_import = new Websocket(`domain/`);

      this.domain_ws_import.onopen = function (event) {
        console.log("Successfully connected to the domain websocket server...")
      }

      this.domain_ws_import.onMessage = function (event, result) {
        // let domain = this.data.find(d => d.name === result.data.domain.name);

        if (result.action === DomainAction.DOMAIN_GET) {
          let row = result.data.row;

          if (
            row.status.toLowerCase() === 'disabled' &&
            (result.data.domains && result.data.domains.length > 0)
          ) {
            // this.pauseRestoreDoain(row);
            let [domain, shouldSave, updateDomainStatus] = this.getDomainSaveUpdateStatus(result);
            this.saveOrUpdateFromWSResponse(row, domain, shouldSave, updateDomainStatus);
          } else if (row.status.toLowerCase() === 'deleted') {
            this.updateStatus(row, "loading", true);
            if (result.data.domains && result.data.domains.length > 0) {
              this.sendDeploy(DomainAction.DOMAIN_DELETE, {
                route_id: result.data.domains[0].domain.id,
                cname: this.$store.getters.cname,
                row: row
              });
            } else {
              this.updateStatus(row, COLUMN_KEYS.ERRORS, [this.$t('message.PleaseCheckTheDomainNameFirst')]);
            }
          }
          else {
            let [domain, shouldSave, updateDomainStatus] = this.getDomainSaveUpdateStatus(result);
            this.saveOrUpdateFromWSResponse(row, domain, shouldSave, updateDomainStatus);
          }
        }

        if (result.action === DomainAction.DOMAIN_CREATE) {
          if (result.data.error) {
            if (result.data.error.hosts) {
              this.updateStatus(result.data.row, "loading", false);
              this.updateStatus(result.data.row, COLUMN_KEYS.ERRORS, [result.data.error.hosts[0]]);
            } else if (result.data.error.upstream) {
              this.updateStatus(result.data.row, "loading", false);
              this.updateStatus(result.data.row, COLUMN_KEYS.ERRORS, [result.data.error.upstream[0]]);
            } else if (result.data.error.service_protocol) {
              this.updateStatus(result.data.row, "loading", false);
              this.updateStatus(result.data.row, COLUMN_KEYS.ERRORS, [result.data.error.service_protocol[0]]);
            } else {
              this.updateStatus(result.data.row, "loading", false);
              this.updateStatus(result.data.row, COLUMN_KEYS.ERRORS, [result.data.error]);
            }
          } else {
            this.updateStatus(result.data.row, "loading", false);
            this.updateStatus(result.data.row, COLUMN_KEYS.RESULT, this.$t('message.domain_created'));
          }
        }

        if (result.action === DomainAction.DOMAIN_UPDATE) {
          if (result.data.error) {
            if (result.data.error.hosts) {
              this.updateStatus(result.data.row, "loading", false);
              this.updateStatus(result.data.row, COLUMN_KEYS.ERRORS, [result.data.error.hosts[0]]);
            } else if (result.data.error.upstream) {
              this.updateStatus(result.data.row, "loading", false);
              this.updateStatus(result.data.row, COLUMN_KEYS.ERRORS, [result.data.error.upstream[0]]);
            } else if (result.data.error.service_protocol) {
              this.updateStatus(result.data.row, "loading", false);
              this.updateStatus(result.data.row, COLUMN_KEYS.ERRORS, [result.data.error.service_protocol[0]]);
            } else {
              this.updateStatus(result.data.row, "loading", false);
              this.updateStatus(result.data.row, COLUMN_KEYS.ERRORS, [result.data.error]);
            }
          } else {
            this.updateStatus(result.data.row, "loading", false);
            this.updateStatus(result.data.row, COLUMN_KEYS.RESULT, this.$t('message.domain_updated'));
          }
        }

        if (result.action === DomainAction.PAUSE_RESTORE_DOMAIN) {
          let row = result.data.row;
          this.updateStatus(row, "loading", false);
          if (result.data.message.includes('updated successfully')) {
            this.updateStatus(row, COLUMN_KEYS.RESULT, this.$t('message.domain_disabled'));
          } else {
            this.updateStatus(row, COLUMN_KEYS.ERRORS, [this.$t('message.failed_to_deactivate_domain')]);
          }
        }

        if (result.action === DomainAction.DOMAIN_DELETE) {
          let row = result.data.row;
          this.updateStatus(row, "loading", false);
          if (result.data.message.includes('deleted successfully')) {
            this.updateStatus(result.data.row, COLUMN_KEYS.RESULT, this.$t('message.SuccessfullyDeleted'));
          } else if (result.data.error) {
            this.updateStatus(result.data.row, COLUMN_KEYS.ERRORS, [this.$t('message.PleaseCheckTheDomainNameFirst')]);
          }
        }

      }.bind(this)
    },
    beforeDestroy() {
      this.closeWs();
    },
    closeWs() {
      this.domain_ws_import.connection.close();
    },
    sendDeploy(action, domains) {
      domains.client_ip = this.clientIP.data.ip;
      this.domain_ws_import.sendMessage(action, domains);
    },
    notifyUser(batch_type) {
      sendNotification(batch_type).then(response => {

      }).catch(({ errors }) => {
        console.log(errors);
      });
    },
    async makeCallToAPI(temp) {

      this.queueDelete = [];
      this.queueCreate = [];

      for (let row of temp) {

        const status = row[COLUMN_KEYS.STATUS].toLowerCase();

        // Force to lower case domain and shadow domain.
        row[COLUMN_KEYS.NAME] = row[COLUMN_KEYS.NAME].toLowerCase();
        row[COLUMN_KEYS.SHADOW_DOMAINS] = row[COLUMN_KEYS.SHADOW_DOMAINS].toLowerCase();
        row[COLUMN_KEYS.AUTO_GENERATE_CERT] = row[COLUMN_KEYS.AUTO_GENERATE_CERT] === 'true';

        if (status === 'disabled') {
          await this.deactivateDomain(row);
        } else if (status === 'deleted') {
          this.queueDelete.push(row);
        } else {
          this.queueCreate.push(row);
        }
      }

      if (this.queueDelete.length) {
        this.onProgressDelete = this.queueDelete;
        this.onProgressDelete.forEach(async (row) => {
          this.sendDeploy(DomainAction.DOMAIN_GET, {
            "domain_name": row[COLUMN_KEYS.NAME].trim(),
            "row": row
          });
        });
      }

      if (this.queueCreate.length) {
        this.onProgressCreate = this.queueCreate;

        for (var i=0; i<this.queueCreate.length; i++) {
            if (this.queueCreate[i].clear_redis){
                await this.sleep(5000);
            }
            await this.saveOrResume(this.queueCreate[i]);
            if (i==0) {
                await this.sleep(5000);
            } else {
                await this.sleep(300);
            }
        }
      }
    },
    async startImport() {
      if (this.isDomainImportInProgress == false) {
        this.isDomainImportInProgress = true;
        this.flash(this.$t('message.upload_in_progress'), 'success', { timeout: 4000 });
        for (var k = 0; (k < this.data.length); k++) {
            this.updateStatus(this.data[k], "loading", true);
        }
        var temp = [];

        if (this.data.length > 0) {
            this.data[0]['clear_redis'] = true;
            this.data[this.data.length - 1]['clear_redis'] = true;
        }

        const clientIP = await getClientIP();

        for (var i = 0; i < this.data.length;) {
          temp = [];
          this.queueDelete = [];
          this.queueCreate = [];
          
          for (var j = 0; (j < 50 && i < this.data.length); j++) {
            this.data[i].client_Ip = clientIP.data.ip;
            temp.push(this.data[i]);
            i++;
          }
          
          await this.sleep(7000);

          // make API call with current values of temp array
          temp[temp.length - 1]['hit_broadcast'] = true;
          await this.makeCallToAPI(temp);
        }

        this.notifyUser('import');
        this.flash(this.$t('message.UploadCompleted'), 'success', { timeout: 4000 });
        this.isDomainImportInProgress = false;
      }
    },
    updateStatus(row, status, message) {
      if (row) {
        const _data = this.data.map((datum) => {
          if (datum._id === row._id) {
            const _datum = { ...datum };
            if (!_datum[status]) {
              _datum[status] = {};
            }

            _datum[status] = message;

            return _datum;
          }

          return datum;
        });

        this.mutatedData = _data;
      }
    },
    async pauseRestoreDoain(row) {

      let isPaused = false;

      if (row.status.toLowerCase() == 'disabled') {
        isPaused = true;
      } else {
        isPaused = false;
      }

      this.sendDeploy(DomainAction.PAUSE_RESTORE_DOMAIN, {
        "hosts": [row[COLUMN_KEYS.NAME].trim()],
        "paused": isPaused,
        "row": row
      });
    },
    updateDomain(createDomainData, row) {
      try {
          this.sendDeploy(DomainAction.DOMAIN_UPDATE, createDomainData);
          return true
      } catch (error) {
        error = error.error;
        if (error && error.response && error.response.data && error.response.data.field_errors) {
          if (error.response.data.field_errors.hosts) {
            if (error.response.data.field_errors.hosts[0].includes('already exist')) {
              this.updateStatus(row, COLUMN_KEYS.ERRORS, [this.$t('message.domain_already_exist')]);
              return;
            }
          }
        }
        this.updateStatus(row, COLUMN_KEYS.ERRORS, [this.$t('message.PleaseCheckTheDomainNameFirst')]);
        return;

      }
    },
    getDomainSaveUpdateStatus(response) {
      let shouldSave = false;
      let updateDomainStatus = false;
      let domain;

      try {
        if (response.data.domains && response.data.domains[0]) {
          domain = response.data.domains[0];
          shouldSave = true;
          updateDomainStatus = true;
        } else {
          shouldSave = true;
        }
      } catch {
        shouldSave = false;
      }

      return [domain, shouldSave, updateDomainStatus];
    },
    async saveOrUpdateFromWSResponse(row, domain, shouldSave, updateDomainStatus) {
      function cleanInput(val) {
        return (val || '').trim();
      }

      const hasAutoGenerateCertificate = row[COLUMN_KEYS.AUTO_GENERATE_CERT];
      const rawShadowDomain = (row[COLUMN_KEYS.SHADOW_DOMAINS] || '').trim();
      let shadowDomains = rawShadowDomain.length ? rawShadowDomain.split(',') : [];

      shadowDomains = shadowDomains.join().split(/\s|,/).filter(Boolean);
      shadowDomains = shadowDomains.join().split(/\""|,/).filter(Boolean);
      let shadowdomainsList = shadowDomains;

      if (shouldSave) {
        this.updateStatus(row, "loading", true);
        row.name = cleanInput(row.name);

        if (row.name === '' || !this.domain_validator(row.name)) {
          this.updateStatus(row, COLUMN_KEYS.ERRORS, [this.$t('message.PleaseCheckTheDomainNameFirst')]);
          return;
        }

        if (shadowDomains.length) {
          const shadowDomainValidationErrors = [];
          for (let s of shadowDomains) {
            if (!this.shadow_domain_validator(s)) {
              shadowDomainValidationErrors.push(this.$t('message.InvalidShadowDomains', [s]));
            }
          }

          if (shadowDomainValidationErrors.length) {
            this.updateStatus(row, COLUMN_KEYS.ERRORS, shadowDomainValidationErrors);
            return;
          }
        }

        shadowDomains = shadowDomains.map(item => {
          return { name: item };
        });
        
        const targets = [];
        const origin_ips = row.origin_ip.split(',');
        origin_ips.forEach((origin) => {
          const origin_ip_split = origin.split(':');
          if (origin_ip_split.length === 1) {
            origin += ':80';
          } 
          targets.push({"target": origin});
        });

        let isPaused = false;

        if (row.status.toLowerCase() == 'disabled') {
          isPaused = true;
        } else {
          isPaused = false;
        }

        const createDomainData = {
          "domain": {
            "hosts": [row.name],
            "protocols": ["http", "https"],
            "upstream": {
              "targets": targets 
            },
            "service": {
              "protocol": row.protocol,
              "tags": []
            },
            "plugins": []
          },
          "meta": {
            "cname": this.$store.getters.cname,
            "description": row.remark
          },
          "paused": isPaused,
          "row": row
        }

        if (hasAutoGenerateCertificate) {
          createDomainData['domain']['service']['tags'] = [TAG_NAMES.HAS_AUTO_GENERATE_CERTIFICATE];
        }

        if (row.shadow_domains) {
          createDomainData['domain']['hosts'] = createDomainData['domain']['hosts'].concat(shadowdomainsList);
        }

        if (updateDomainStatus) {
          createDomainData['route_id'] = domain.domain.id;

          this.updateDomain(createDomainData, row);
          return;
        } else {
          try {
            this.sendDeploy(DomainAction.DOMAIN_CREATE, createDomainData);
          } catch (error) {
            error = error.error;
            if (error && error.response && error.response.data && error.response.data.field_errors) {
              if (error.response.data.field_errors.hosts) {
                if (error.response.data.field_errors.hosts[0].includes('already exist')) {
                    this.updateStatus(row, COLUMN_KEYS.ERRORS, [this.$t('message.domain_already_exist')]);
                  return;
                }
              }
            }
            this.updateStatus(row, COLUMN_KEYS.ERRORS, [this.$t('message.PleaseCheckTheDomainNameFirst')]);
            return;

          }
          return;
        }
      }

      const shouldReactivate = domain.domain.status == 'Deactivated';

      if (shouldReactivate) {
        let resumeResponse;
        try {
          this.sendDeploy(DomainAction.PAUSE_RESTORE_DOMAIN, {
            "hosts": [domainName],
            "paused": false,
            "row": row
          });
        } catch (errors) {
          this.updateStatus(row, COLUMN_KEYS.ERRORS, errors);
          return;
        }

        if (resumeResponse.status == 200) {
          this.updateStatus(row, COLUMN_KEYS.RESULT, this.$t('message.domain_reactivated'));
        } else {
          this.updateStatus(row, COLUMN_KEYS.ERRORS, [this.$t('message.failed_to_reactivate_domain')]);
        }
        return;
      }

      this.updateStatus(row, COLUMN_KEYS.ERRORS, [this.$t('message.domain_already_exist')]);
    },
    async saveOrResume(row) {
      const domainName = row[COLUMN_KEYS.NAME].trim();
      const rawShadowDomain = (row[COLUMN_KEYS.SHADOW_DOMAINS] || '').trim();
      let shadowDomains = rawShadowDomain.length ? rawShadowDomain.split(MULTIPLE_VALUES_SPLITTER) : [];

      this.sendDeploy(DomainAction.DOMAIN_GET, {
        "domain_name": domainName,
        "row": row
      });
    },
    async deactivateDomain(row) {
      this.updateStatus(row, "loading", true);

      try {
        this.sendDeploy(DomainAction.DOMAIN_GET, {
          "domain_name": row[COLUMN_KEYS.NAME].trim(),
          "row": row
        });
      } catch (errors) {
        this.updateStatus(row, COLUMN_KEYS.ERRORS, errors);
        return;
      }
      // const success = response.status == "200";
      // if (success) {
      //   this.updateStatus(row, COLUMN_KEYS.RESULT, this.$t('message.domain_disabled'));
      // } else {
      //   this.updateStatus(row, COLUMN_KEYS.ERRORS, [this.$t('message.failed_to_deactivate_domain')]);
      // }
    },
    handleSelectFile() {
      // reset selection
      this.$refs.fileInput.value = '';

      this.$refs.fileInput.click();
      this.longDomainErrorShown = false;
    },
    dragEnter(evt) {
      evt.stopPropagation();
      evt.preventDefault();

      this.clearDragFlag();

      this.hasDraggedItem = true;
      this.clearDragFlagRef = setTimeout(() => {
        this.hasDraggedItem = false;
      }, 3000);
    },
    clearDragFlag() {
      if (this.clearDragFlagRef) {
        clearTimeout(this.clearDragFlagRef);
        this.clearDragFlagRef = null;
      }
    },
    _drop(evt) {
      evt.stopPropagation();
      evt.preventDefault();

      this.clearDragFlag();
      this.hasDraggedItem = false;

      const files = evt.dataTransfer.files;
      if (files && files[0]) this._file(files[0]);
    },
    _change(evt) {
      const files = evt.target.files;
      if (files && files[0]) this._file(files[0]);
    },
    _export() {
      this.setExportImportLang();
      const sheetSampleData = [{
        [COLUMN_KEYS.NAME]: 'v03.example.com',
        [COLUMN_KEYS.ORIGIN_IP]: '8.8.8.8',
        [COLUMN_KEYS.PROTOCOL]: 'http',
        [COLUMN_KEYS.SHADOW_DOMAINS]: 'xyz.example.com shadow.example.com',
        [COLUMN_KEYS.STATUS]: this.$t('online'),
        [COLUMN_KEYS.AUTO_GENERATE_CERT]: false,
        [COLUMN_KEYS.REMARK]: "notes"
      },
      {
        [COLUMN_KEYS.NAME]: 'v04.example.com',
        [COLUMN_KEYS.ORIGIN_IP]: '8.8.8.8',
        [COLUMN_KEYS.PROTOCOL]: 'https',
        [COLUMN_KEYS.SHADOW_DOMAINS]: '',
        [COLUMN_KEYS.STATUS]: this.$t('disabled'),
        [COLUMN_KEYS.AUTO_GENERATE_CERT]: true,
        [COLUMN_KEYS.REMARK]: "notes"
      },
      {
        [COLUMN_KEYS.NAME]: 'v05.example.com',
        [COLUMN_KEYS.ORIGIN_IP]: '8.8.8.8,8.8.4.4',
        [COLUMN_KEYS.PROTOCOL]: 'http',
        [COLUMN_KEYS.SHADOW_DOMAINS]: '',
        [COLUMN_KEYS.STATUS]: this.$t('deleted'),
        [COLUMN_KEYS.AUTO_GENERATE_CERT]: false,
        [COLUMN_KEYS.REMARK]: "notes"
      }];
      this._exportDomainData(sheetSampleData);
    },
    _file(file) {
      const reader = new FileReader();

      reader.onload = e => {

        function cleanInput(val) {
          return (val.toString() || '').trim();
        }

        this.setExportImportLang();
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const resultJson = XLSX.utils.sheet_to_json(ws, { header: this.exportFields.map((f) => f.key) });

        if (resultJson.length < 2) {
          this.flash(this.$t('message.empty_import_file'), 'error', { "timeout": 5000 });
          return;
        }

        let [_headerRow, ...data] = resultJson;

        Object.keys(data).forEach(key => {
          if (data[key].name === '' || data[key].name === undefined) {
            delete data[key]
          }
        });

        data = data.filter(i => i !== null);

        const hasDomainColumn = _headerRow[COLUMN_KEYS.NAME] === this.$t("domain.Domain");
        const hasOriginIpColumn = _headerRow[COLUMN_KEYS.ORIGIN_IP] === this.$t("domain.OriginIP");
        const hasStatusColumn = _headerRow[COLUMN_KEYS.STATUS] === this.$t("domain.Status");

        if (!hasDomainColumn || !hasOriginIpColumn || !hasStatusColumn) {
          this.flash(this.$t('message.import_file_no_header'), 'error', { "timeout": 8000 });
          return;
        }

        const statusAcceptedValues = [this.$t('online'), this.$t('disabled'), this.$t('deleted')].map((v) => v.toLowerCase());
        const statusValidationPassed = data.every((datum) => {
          const datumElement = datum[COLUMN_KEYS.STATUS];
          if (!datumElement)
            return false;

          return statusAcceptedValues.includes(cleanInput(datumElement).toLowerCase());
        });

        if (!statusValidationPassed) {
          this.flash(this.$t('message.allowed_status_only') + ' ' + statusAcceptedValues.join(', '), 'error', { "timeout": 8000 });
          return;
        }

        const originValidationPassed = data.every((datum) => {
          const datumElement = datum[COLUMN_KEYS.ORIGIN_IP];
          if (!datumElement)
            return false;
          return this.source_validator(cleanInput(datumElement));
        });
        if (!originValidationPassed) {
          this.flash(this.$t('message.Required_origin_ip'), 'error', { "timeout": 5000 });
          return;
        }

        const cellsValidationPassed = data.every((datum) => {
          const shouldRequestOriginIpAndGroup = cleanInput(datum[COLUMN_KEYS.STATUS]).toLowerCase() === 'online';
          const isDomainValid = this.domain_validator(cleanInput(datum[COLUMN_KEYS.NAME]));

          if (shouldRequestOriginIpAndGroup && datum[COLUMN_KEYS.NAME].split('.')[0].length > 63) {
            if (!this.longDomainErrorShown) {
              this.longDomainErrorShown = true;
              this.flash(this.$t('message.DomainNameValidationError'), 'error', { "timeout": 5000 });
            }
            return false;
          }

          return isDomainValid && (!shouldRequestOriginIpAndGroup || this.source_validator(cleanInput(datum[COLUMN_KEYS.ORIGIN_IP])));
        });
        if (!cellsValidationPassed) {
          if (!this.longDomainErrorShown) {
            this.flash(this.$t('message.cell_value_validation_failed'), 'error', { "timeout": 5000 });
          }
          return;
        }

        this.mutatedData = data.map((datum) => {
          datum._id = Math.random();
          datum[COLUMN_KEYS.ORIGIN_IP] = datum[COLUMN_KEYS.ORIGIN_IP] ? cleanInput(datum[COLUMN_KEYS.ORIGIN_IP]) : '';
          datum[COLUMN_KEYS.SHADOW_DOMAINS] = datum[COLUMN_KEYS.SHADOW_DOMAINS] ? cleanInput(datum[COLUMN_KEYS.SHADOW_DOMAINS]) : '';
          datum[COLUMN_KEYS.REMARK] = datum[COLUMN_KEYS.REMARK] ? cleanInput(datum[COLUMN_KEYS.REMARK]) : '-';
          datum[COLUMN_KEYS.AUTO_GENERATE_CERT] = datum[COLUMN_KEYS.AUTO_GENERATE_CERT] ? cleanInput(datum[COLUMN_KEYS.AUTO_GENERATE_CERT]).toLowerCase() : 'false';
          return datum;
        });

        this.startImport();
        this.resetLang();
      };
      reader.readAsBinaryString(file);
    }
  }
};
</script>
