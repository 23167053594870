import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import ReconnectingWebSocket from 'reconnecting-websocket';
import { Action } from '@/utilities/websocket'
import { getClientIP } from '../utilities/api';

const rootDomain = location.hostname.split('.').reverse().splice(0,2).reverse().join('.')

export function Websocket(path, base = null) {
  const url = new URL(path,
    (base || process.env.VUE_APP_WSS || 'wss://localhost:3000/ws/').replace("suyuncdn3.com", rootDomain )
  )

  const urlProvider = async () => {
    const auth = store.getters.SECURITY_AUTH;
    const search_params = new URLSearchParams({token: auth.token, realm: auth.realm});
    const params = await search_params.toString();
    return `${url.href}?${params}`
  }

  this.connection = new ReconnectingWebSocket(urlProvider);
  this.connection.debug = true;
  this.connection.timeoutInterval = 10000;

  this.sendMessage = (action, data, client_ip=null) => {  
    const context = {
      action: action,
      data: data
    }
    if (client_ip) {
      context['client_ip'] = client_ip
    }
    return this.connection.send(JSON.stringify(context))
  }

  this.onMessage = (event, result) => {
    // console.log("=======>>>>onMessage event", event);
    // console.log("=======>>>>onMessage result", result);
    // this.notifySuccess(result.data.message);
  }

  this.isOpen = () => {
    return this.connection.readyState === WebSocket.OPEN;
  }

  this.connection.onmessage = (event) => {
    const result = JSON.parse(event.data)
    if (result.action === Action.AUTH_ERROR) {
      Vue.prototype.$flashStorage.flash(result.data.error, 'error', {'timeout': 8000})
      setTimeout(() => {
        router.go()
      }, 8000)
    }
    this.onMessage(event, result)
  }
}
