export const Action = {
  AUTH_ERROR: -1,
  WEBSOCKET_PARSE: 0
}

export const DomainAction = {
  WEBSOCKET_PARSE: 0,
  DOMAIN_REDEPLOY: 1,
  DOMAIN_RESUME: 2,
  DOMAIN_DEACTIVATE: 3,
  CLEAR_CACHE: 4,
  DOMAIN_GET: 5,
  DOMAIN_CREATE: 6,
  DOMAIN_UPDATE: 7,
  DOMAIN_DELETE: 8,
  PAUSE_RESTORE_DOMAIN: 9,
  DOMAIN_CHECKER: 10,
  FORCE_HTTPS: 11,
  ENABLE_HTTPS: 12,
  DOMAIN_UPDATES: 13,
  CLEAR_CUSTOM_CACHE: 14
}

export const CertAction = {
  WEBSOCKET_PARSE: 0,
  CERT_ISSUE: 1
}

export const ShadowDomainAction = {
  CNAME_CHECK_ERROR: -3,
  WEBSOCKET_PARSE: 0,
  SHADOW_DOMAIN: 1
}

export const CertificateAction = {
  GENERATE_CERT: 1,
  UPLOAD_CERT: 2
};
