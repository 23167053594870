import isValidDomain from 'is-valid-domain';

const DomainValidationMixin = {
    methods: {
        domain_validator(val) {

          return val == "" || isValidDomain(val, { wildcard: true });
        },
        shadow_domain_validator(val) {

          return val == "" || this.domain_validator(val.replace(new RegExp(/^\*\./), '').replaceAll(',', ''));
        },
        source_hostname_validator(val) {
            return /^(?:https?:\/\/)?(?:[\w-]+\.)+[a-zA-Z]{2,}(?::\d+)?(?:\/\d+\/)?(?:,\s*)?$/.test(val);
        },
        source_ipv4_validator(val) {
          if (val === '127.0.0.1') {
            return false;
          }
          return /([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}):?(\d{1,5})?$/.test(
              val
          );
        },
        source_validator(val) {
            return /^(([a-z0-9-]{1,61}\.){1,3}[a-z0-9-]{1,61}(:\d{2,5})?\/?\,?)+$/.test(
                val
            );
        },
        source_ipv6_validator(val) {
          if (val === '::1') {
            return false;
          }
          return /^((?:[0-9A-Fa-f]{1,4}:){7}[0-9A-Fa-f]{1,4}|(?:[0-9A-Fa-f]{1,4}:){1,6}:(?:[0-9A-Fa-f]{1,4}:){0,1}[0-9A-Fa-f]{1,4}|(?:[0-9A-Fa-f]{1,4}:){1,5}:(?:[0-9A-Fa-f]{1,4}:){0,2}[0-9A-Fa-f]{1,4}|(?:[0-9A-Fa-f]{1,4}:){1,4}:(?:[0-9A-Fa-f]{1,4}:){0,3}[0-9A-Fa-f]{1,4}|(?:[0-9A-Fa-f]{1,4}:){1,3}:(?:[0-9A-Fa-f]{1,4}:){0,4}[0-9A-Fa-f]{1,4}|(?:[0-9A-Fa-f]{1,4}:){1,2}:(?:[0-9A-Fa-f]{1,4}:){0,5}[0-9A-Fa-f]{1,4}|[0-9A-Fa-f]{1,4}:((?:[0-9A-Fa-f]{1,4}:){0,6}|:)|:(?::(?:[0-9A-Fa-f]{1,4}:){0,6}|:(?:[0-9A-Fa-f]{1,4}:){0,5}[0-9A-Fa-f]{1,4}|:(?:[0-9A-Fa-f]{1,4}:){0,4}[0-9A-Fa-f]{1,4}|:(?:[0-9A-Fa-f]{1,4}:){0,3}[0-9A-Fa-f]{1,4}|:(?:[0-9A-Fa-f]{1,4}:){0,2}[0-9A-Fa-f]{1,4}|:(?:[0-9A-Fa-f]{1,4}:){0,1}[0-9A-Fa-f]{1,4}|:))$/.test(
              val
          );
        },
        path_validator(val) {
            return val == '' || /^(\^)?\/?([\w-.~*]+\/?)+(\$)?$/.test(val)
        }
    }
}

export default DomainValidationMixin;
